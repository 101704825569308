.demoform{
    width: 100%;
    height: max-content;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
}
.demoform .container-lg{
    width: 90%;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 32px 54px rgba(29, 41, 63, 0.04);
    border-radius: 16px;
    border: 1px solid #e5eaf4;
}
.demoform .container-lg h1{
    color: #2b3c55;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}
.demoform .container-lg .line{
    width: 100px;
    height: 3px;
    background: #3078ff;
    margin: 0 auto;
}
.demoform .container-lg #success-demo{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 300px;
    height: auto;
}
.demoform .container-lg #success-demo .box{
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.demoform .container-lg #success-demo .box img{
    width: 50px !important;
    background: transparent;
    margin: 10px 0;
}
.demoform .container-lg .form{
    padding-top: 30px;
}
.demoform .container-lg .form .part2 .archive,
.demoform .container-lg .form .part2 .case,
.demoform .container-lg .form .part2 .approval,
.demoform .container-lg .form .part2 .row2,
.demoform .container-lg .form .part2 .row1{
    margin-left: 33px !important;
}
.demoform .container-lg .form .part1{
    width: 100%;
    height: 35%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.demoform .container-lg .form .part1 div{
    width: 300px;
    margin: 10px 30px;
    position: relative;
}
.demoform .container-lg .form .part1 div label{
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 18px;
    transition: .3s;
    color: #5a7184;
    opacity: .75;
    font-weight: 450;
}
.demoform .container-lg .form .part1 div input{
    border: none;
    outline: none;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
    padding: 10px 0;
    color: #2b3c55;
    font-weight: 500;
    font-size: 18px;
    width: 350px;
}
.demoform .container-lg .form .part1 div input:focus ~ label{
    top: -12px !important;
    transition: .3s !important;
}
.demoform .container-lg .form .part2 h3{
    padding: 0;
    margin: 0;
    padding-left: 33px;
    color: #2b3c55;
    font-size: 18px;
    font-weight: 500;
    mix-blend-mode: normal;
    opacity: .75;
}
.demoform .container-lg .form .part2 .row1,
.demoform .container-lg .form .part2 .row2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.demoform .container-lg .form .part2 div{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 30%;
}
.demoform .container-lg .form .part2 div input{
    -webkit-appearance: none;
    display: none;
    opacity: 0;
}
.demoform .container-lg .form .part2 div label{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #5a7184;
    position: relative;
}
.demoform .container-lg .form .part2 div span{
    font-size: 18px;
    color: #5a7184;
    margin: 18px 10px;
}
.demoform .container-lg .form .part2 div input:checked ~ label{
    border: 1px solid #3078ff;
}
.demoform .container-lg .form .part2 div input:checked ~ label::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/check.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
}
.demoform .container-lg .form .part2 .row1, 
.demoform .container-lg .form .part2 .row2{
    display: flex;
}
.demoform .container-lg .form .part3{
    display: flex;
    justify-content: center;
    align-items: center;
}
.demoform .container-lg .form .part3 button{
    width: 200px;
    height: 56px;
    background: #2abcff;
    border: none;
    border-radius: 100px;
    margin-bottom: 54px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    outline: none;
}
@media (max-width: 1200px){
    .demoform .container-lg .form .part1{
        display: flex;
        justify-content: space-evenly;
    }
}
@media (max-width: 991px){
    .demoform .container-lg .form .part1 div input{
        width: 280px;
    }
}
@media (max-width: 768px){
    .demoform .container-lg .form .part2 .archive{
        width: 100%;
    }
    .demoform .container-lg .form .part1{
        justify-content: space-evenly;
    }
    .demoform .container-lg .form .part1 div{
        margin: 15px 20px;
        width: 220px;
    }
    .demoform .container-lg .form .part1 div input{
        width: 200px;
    }
    .demoform .container-lg .form .part2 .row1,
    .demoform .container-lg .form .part2 .row2{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .demoform .container-lg .form .part2 .row1 div,
    .demoform .container-lg .form .part2 .row2 div{
        width: 45%;
    }
    .demoform .container-lg .form .part2 .case,
    .demoform .container-lg .form .part2 .approval{
        width: 100%;
    }
}
@media (max-width: 576px){
    .demoform .container-lg h1{
        font-size: 25px;
    }
    .demoform .container-lg .form .part1{
        display: flex;
        justify-content: flex-start;
    }
    .demoform .container-lg .form .part2 .row1 div,
    .demoform .container-lg .form .part2 .row2 div{
        width: 100%;
    }
}