@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.footer{
    width: 100%;
    height: 100vh;
    background: #2b3c55;
}
.footer .contact-form{
    width: 100%;
    background: #2b3c55;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.footer .contact-form .left-col,
.footer .contact-form .right-col{
    width: 50%;
    height: auto;
    min-height: 700px;
}
.footer .contact-form .left-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;    
    position: relative;
    padding-left: 150px;
}
.footer .contact-form .left-col h1{
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    width: 100%;
    text-align: left;
}
.footer .contact-form .left-col .line{
    width: 93px;
    height: 3px;
    background: #3078ff;
    margin-left: 0px;
    align-self: flex-start;
    margin-bottom: 16px;
}
.footer .contact-form .left-col p{
    color: #fff;
    font-weight: 450;
    align-self: flex-start;
    margin-bottom: 38px;       
}
.footer .contact-form .left-col ul{
    list-style: none;
    align-self: flex-start;
    width: 100%;
}
.footer .contact-form .left-col ul li{
    margin: 31px 0;
}
.footer .contact-form .left-col ul li span{
    color: #fff;
}
.footer .contact-form .left-col ul li img{
    padding-right: 20px;
}
.footer .contact-form .right-col{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .contact-form .right-col .form,
.footer .contact-form .right-col #success-footer{
    width: 470px;
    height: 456px;
    background: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    padding-top: 30px;
}
.footer .contact-form .right-col #success-footer{
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
}
.footer .contact-form .right-col .form div{
    width: 90%;
    position: relative;
    margin-left: 20px;
    margin: 10px 30px;
}
.footer .contact-form .right-col .form div input,
.footer .contact-form .right-col .form div textarea{
    border: none;
    outline: none;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
    padding: 10px 0;
    color: #2b3c55;
    font-weight: 500;
    font-size: 18px;
    width: 405px;
}
.footer .contact-form .right-col .form div label{
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 18px;
    transition: .3s;
    color: #5a7184;
    opacity: .75;
    font-weight: 450;
}
.footer .contact-form .right-col .form div input:focus ~ label,
.footer .contact-form .right-col .form div textarea:focus ~ label{
    top: -12px !important;
}
.footer .contact-form .right-col .form div:last-child button{
    width: 200px;
    height: 56px;
    padding: 13px 68px;
    color: #fff;
    background-color: #2abcff;
    border: none;
    border-radius: 100px;
}
.footer .foot{
    width: 100%;
    height: auto;
    background: #25354e;
    min-height: 270px;
}
.footer .foot .container-lg{
    position: relative;
    width: 100% !important;
}
.footer .foot .container-lg img{
    position: absolute;
    top: 40px;
}
.footer .foot .container-lg .tabs{
    display: flex;
    min-width: 100% !important;
    width: auto;
    list-style: none;
    position: absolute;
    top: 50px;
    padding-left: 60%;
}
.footer .foot .container-lg .tabs li{
    margin: 0 10px;
    width: 80px;
    text-align: center;
}
.footer .foot .container-lg .tabs li:last-child{
    margin-right: 0 !important;
}
.footer .foot .container-lg .tabs li a{
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 450;
}
.footer .foot .container-lg .tabs li:hover a,
.footer .foot .container-lg .tabs li a.active{
    color: #2abcff;
}
.footer .foot .container-lg .social-media{
    display: flex;
    min-width: 100% !important;
    width: auto;
    list-style: none;
    position: absolute;
    top: 128px;
    padding-left: 68%;
}
.footer .foot .container-lg .social-media li{
    margin: 0 20px;
}
.footer .foot .container-lg .social-media li #sicon,
.footer .foot .container-lg .social-media li svg{
    width: 18px;
    height: 21px;
    color: #5a7184;
}
.footer .foot .container-lg .social-media li svg{
    width: 18px;
    height: 15px;
}
.footer .foot .container-lg .social-media li:last-child{
    margin-right: 0;
}
.footer .foot .container-lg .terms{
    position: absolute;
    top: 126px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.footer .foot .container-lg .terms p{
    font-size: 14px;
    font-weight: 450;
}
.footer .foot .container-lg .terms p a{
    text-decoration: none;
    color: #ebebeb;
    font-weight: 450;
    font-size: 14px;
}
.footer .foot .container-lg .divider{
    width: 99%;
    height: 1px;
    background: #ccc;
    opacity: 0.2;
    position: absolute;
    top: 175px;
    left: 50%;
    transform: translateX(-50%);
}
.footer .foot .container-lg .bottom{
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    width: auto;
    text-align: center;
}
.footer .foot .container-lg .bottom p{
    color: #ebebeb;
    font-size: 14px;
    font-weight: 450;
}
.footer .contact-form{
    position: relative;
}
.footer .contact-form .back-to-top{
    position: absolute;
    top: -20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #2abcff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .contact-form .back-to-top #icon{
    color: #fff;
    transform: scaleY(1.4) scaleX(1.1);
}
@media (max-width: 1200px){
    .footer .contact-form .left-col{
        padding-left: 20px;
    }
    .footer .contact-form .left-col .line{
        transform: translateX(0);
    }
    .footer .foot .container-lg .top .tabs{
        padding-left: 55%;
    }
    .footer .foot .container-lg .top .social-media{
        padding-left: 65%;
    }
}
@media (max-width: 991px){
    .footer .contact-form{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .footer .contact-form .left-col,
    .footer .contact-form .right-col{
        width: 100%;
    }
    .footer .contact-form .left-col h1{
        margin-left: 0;
    }
    .footer .contact-form .left-col p,
    .footer .contact-form .left-col .line,
    .footer .contact-form .left-col ul{
        margin-left: 0;
    }
    .footer .contact-form .right-col{
        display: flex;
        justify-content: flex-start;
        padding-left: 20px;
        margin-top: -25%;
    }
    .footer .foot .container-lg .top .tabs{
        position: absolute;
        top: 50px;
        right: 20px;
    }
    .footer .foot .container-lg .top .social-media{
        position: absolute;
        right: 20px;
    }
    .footer .foot .container-lg .top .terms{
        position: absolute;
        left: 20px;
    }
}
@media (max-width: 768px){
    .footer .foot .container-lg .top .tabs{
        position: absolute;
        top: 108px;
        padding-left: 20px;
    }
    .footer .foot .container-lg .top .social-media{
        position: absolute;
        top: 150px;
        right: 20px;
    }
    .footer .foot .container-lg .top .terms{
        position: absolute;
        top: 150px;
        left: 20px;
    }
    .footer .foot .container-lg .divider{
        top: 180px;
    }
}
@media (max-width: 576px){
    .footer .contact-form .right-col .form,
    .footer .contact-form .right-col #success-footer{
        width: 400px;
    }
    .footer .contact-form .right-col .form input,
    .footer .contact-form .right-col .form textarea{
        width: 350px !important;
    }
    .footer .foot .container-lg .top .social-media{
        position: absolute;
        top: 150px;
        padding-left: 30px;
    }
    .footer .foot .container-lg .top .terms{
        position: absolute;
        top: 190px;
        padding-left: 10px;
    }
    .footer .foot .container-lg .divider{
        top: 220px;
        width: 90%;
    }
    .footer .foot .container-lg .bottom{
        top: 225px;
    }
}
@media (max-width: 450px){
    .footer .contact-form .left-col ul li img{
        padding-right: 5px;
        transform: scale(.8);
    }
    .footer .contact-form .right-col{
        padding-left: 0;
    }
    .footer .contact-form .right-col .form,
    .footer .contact-form .right-col #success-footer{
        width: 340px;
        margin: 0 auto;
    }
    .footer .contact-form .right-col .form input,
    .footer .contact-form .right-col .form textarea{
        width: 280px !important;
    }
    .footer .foot .container-lg .top .tabs{
        display: flex;
        flex-wrap: wrap !important;
        min-height: 50px;
        height: auto;
    }
    .footer .foot{
        min-height: 350px;
    }
    .footer .foot .container-lg .top .social-media{
        top: 180px;
    }
    .footer .foot .container-lg .top .terms{
        top: 220px;
    }
    .footer .foot .container-lg .divider{
        top: 250px;
    }
    .footer .foot .container-lg .bottom{
        top: 280px;
    }
    .our-products {
        height: 120vh;
    }
}
@media (max-width: 321px){
    .our-products{
        height: 150vh;
    }
    .footer .contact-form .right-col .form,
    .footer .contact-form .right-col #success-footer{
        width: 280px;
    }
    .footer .contact-form .right-col .form input,
    .footer .contact-form .right-col .form textarea{
        width: 250px !important;
    }
    .same-bg .key-features .boxes .box:nth-child(5), .same-bg .key-features .boxes .box:nth-child(6) {
        min-height: 300px !important;
    }
    .same-bg .key-features .boxes .box:nth-child(3), .same-bg .key-features .boxes .box:nth-child(4) {
        min-height: 370px !important;
    }
    .same-bg .key-features .boxes .box {
        min-height: 440px;
    }
}