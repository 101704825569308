.navbar{
    width: 100%;
    height: auto;
}
.navbar .container-lg .navbar-brand img{
    width: 306px;
    height: 51px;
}
.navbar .container-lg button{
    border-color: transparent;
    color: transparent;
}
.navbar .container-lg .collapse ul{
    margin-left: auto !important;
    display: flex;
	justify-content: center;
}
.navbar .container-lg .collapse ul li{
    padding: 0 10px;
}
.navbar .container-lg .collapse ul li a{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.2px;
}
.navbar .container-lg .collapse ul li a:hover,
.navbar .container-lg .collapse ul li a.active{
    color: #2abcff !important;
}
.navbar .container-lg .collapse ul li:last-child{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
}
.navbar .container-lg .collapse ul li:last-child a{
    background: #2abcff;
    color: #fff !important;
    padding: 0;
    margin: 0;
    border-radius: 37px;
    padding: 10px 36px;
    padding-top: 12px;
}
.navbar .container-lg .collapse ul li:last-child:hover a:hover,
.navbar .container-lg .collapse ul li:last-child:hover a,
.navbar .container-lg .collapse ul li:last-child a:hover{
    color: #fff !important;
}
@media (min-width: 991px){
    .navbar{
        width: 100% !important;
        height: 70px;
    }
}
@media (max-width: 991px){
    .navbar .container-lg .collapse ul li:last-child{
        width: 100%;
    }
    .navbar .container-lg .collapse ul li:last-child a{
        width: 100% !important;
    }
}
@media (max-width: 576px){
    .navbar .container-lg .navbar-brand{
        width: 80%;
        margin-right: 0;
    }
    .navbar .container-lg .navbar-brand img{
        width: 100%;
    }
}