@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(http://fonts.cdnfonts.com/css/circular-std);
.welcome{
    width: 100%;
    height: 100vh;
    background: #2b3c55;
}
.welcome .container-lg .welcome-row{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center !important;
    flex-direction: row;
}
.welcome .container-lg .welcome-row .left-col,
.welcome .container-lg .welcome-row .right-col{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.welcome .container-lg .welcome-row .left-col{
    justify-content: flex-start;
    text-align: left;
}
.welcome .container-lg .welcome-row .left-col h1{
    margin-bottom: 20px;
    font-size: 42px;
    font-weight: bold;
}
.welcome .container-lg .welcome-row .left-col h1 b{
    color: #2ABCFF;
}
.welcome .container-lg .welcome-row .left-col h1 span{
    color: #fff;
}
.welcome .container-lg .welcome-row .left-col .short-service{
    display: flex;
    flex-direction: column;
    list-style: none;
}
.welcome .container-lg .welcome-row .left-col .short-service li{
    margin: 5px 0;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
}
.welcome .container-lg .welcome-row .left-col .short-service li .line{
    width: 18px;
    height: 4px;
    background: #3de2ff;
    margin-right: 10px;
    margin-left: -30px;
    border-radius: 35px;
    position: absolute;
    top: 10px;
}
.welcome .container-lg .welcome-row .left-col .short-service li .text{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.welcome .container-lg .welcome-row .right-col img{
    width: 90%;
}
.c-m-r{
    width: 100%;
    height: auto;
    background: #fff;
    position: relative;
}
.c-m-r>h1{
    line-height: 46px;
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 36px;
    width: 100%;
    text-align: center;
    color: #2b3c55;
    font-weight: bold;
}
.c-m-r .line{
    position: absolute;
    top: 155px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #3078ff;
    width: 100px;
    height: 3px;
}
.c-m-r .archive-sec,
.c-m-r .e-discovery-sec{
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center !important;
    flex-direction: row;
    padding-top: 200px !important;
}
.c-m-r .archive-sec{
    min-height: 120vh;
}
.c-m-r .e-discovery-sec{
    width: 100%;
    height: 10vh;
    position: relative;
    overflow: hidden;
    background: #f8fbff;
    display: flex;
    align-items: center;
    padding-top: 0 !important;
}
.c-m-r .e-discovery-sec .left-col,
.c-m-r .e-discovery-sec .right-col{
    z-index: 1 !important;
    position: relative;
}
.c-m-r .e-discovery-sec::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    border-radius: 50%;
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transform: translateX(-50%) scale(2);
            transform: translateX(-50%) scale(2);
}
.c-m-r .archive-sec .left-col{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 7px;
    position: absolute;
    top: 100px;
    left: 0;
    padding-right: 30px;
    min-height: 100vh;
    height: auto;
}
.c-m-r .archive-sec .right-col{
    position: absolute;
    top: 200px;
    left: 50%;
}
.c-m-r .archive-sec .right-col,
.c-m-r .e-discovery-sec .left-col,
.c-m-r .e-discovery-sec .right-col{
    width: 50%;
    display: flex;
    justify-content: baseline;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.c-m-r .e-discovery-sec .right-col{
    position: relative;
    padding-left: 68px;
    height: auto;
}
.c-m-r .archive-sec .left-col img{
    width: 100%;
}
.c-m-r .e-discovery-sec .right-col img{
    width: 100%;
}
.c-m-r .archive-sec .right-col h1{
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    font-weight: 800;
    color: #2b3c55;
    margin: 0;
    padding: 10px 0 21px 0;
}
.c-m-r .archive-sec .right-col p{
    text-align: left;
    width: 100%;
    color: #2b3c55;
    font-size: 16px;
    font-weight: 450;
    padding: 0;
    margin: 0;
    padding: 0 0 24px 0;
}
.c-m-r .archive-sec .right-col .archive{
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 0 0 15px 0;
}
.c-m-r .archive-sec .right-col .boxes{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.c-m-r .archive-sec .right-col .boxes .box{
    width: 170px;
    height: 130px;
    background: #fff;
    box-shadow: 0px 32px 54px rgba(29, 41, 63, 0.04);
    border-radius: 10px;
    margin: 21px 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 !important;
}
.c-m-r .archive-sec .right-col .boxes .box .text{
    color: #183b56;
    font-size: 14px;
}
.c-m-r .e-discovery-sec .left-col{
    padding: 0;
    margin: 0;
    padding-left: 130px;
}
.c-m-r .e-discovery-sec .left-col h1{
    width: 100%;
    text-align: left;
    color: #2b3c55;
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    padding: 0 0 21px 0;
}
.c-m-r .e-discovery-sec .left-col p{
    width: 100%;
    text-align: left !important;
    color: #2b3c55;
    font-size: 16px;
    font-weight: 450;
    padding: 0;
    margin: 0;
}
.same-bg{
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #F8FBFF 0%, rgba(250, 251, 251, 0.0001) 100%);
}
.same-bg .key-features{
    width: 100%;
    height: auto;
    min-height: 100vh;
}
.same-bg .key-features h1{
    padding-top: 20px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #2b3c55;
    font-size: 36px;
}
.same-bg .key-features .line{
    background: #3078ff;
    width: 100px;
    height: 3px;
    margin: 10px auto;
}
.same-bg .key-features .boxes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.same-bg .key-features .boxes .box{
    width: calc(50% - 20px);
    height: auto;
    min-height: 280px;
    max-height: 450px;
    background: #fff;
    margin: 10px;
    position: relative;
    border-radius: 15px;
    border: 1px #e5eaf4 solid;
    box-shadow: 0px 32px 54px rgba(29, 41, 63, 0.04);
}
.same-bg .key-features .boxes .box:nth-child(3),
.same-bg .key-features .boxes .box:nth-child(4){
    min-height: 220px;
}
.same-bg .key-features .boxes .box:nth-child(5),
.same-bg .key-features .boxes .box:nth-child(6){
    min-height: 200px !important;
}
.same-bg .key-features .boxes .box:last-child{
    min-height: 120px;
    width: 100%;
}
.same-bg .key-features .boxes .box .icon{
    position: absolute;
    top: 20px;
    left: 20px;
}
.same-bg .key-features .boxes .box .text{
    position: absolute;
    top: 20px;
    left: 80px;
    width: 75%;
    padding: 0 10px 10px 0 !important;
}
.same-bg .key-features .boxes .box .text h2{
    font-size: 22px;
    font-weight: 500;
    color: #2b3c55;
}
.same-bg .key-features .boxes .box .text p{
    font-size: 16px;
    font-weight: 450;
    color: #2b3c55;
}
.same-bg .our-product{
    width: 100%;
    height: auto !important;
}
.same-bg .our-product h1{
    margin-top: 50px;
    width: 100%;
    text-align: center;
    color: #2b3c55;
}
.same-bg .our-product .line{
    margin: 10px auto;
    width: 100px;
    height: 3px;
    background: #3078ff;
    margin-bottom: 50px !important;
}
.same-bg .our-product .container{
    width: 100%;
    margin: 5% auto;
}
.other-products{
    width: 100%;
    height: 530px;
    background: #f5f7f9;
    position: relative;
}
.other-products>h1{
    width: 100%;
    text-align: center;
    padding-top: 40px;
    color: #2b3c55;
}
.other-products .line{
    width: 100px;
    height: 3px;
    background: #2abcff;
    margin: 10px auto;
}
.other-products>p{
    width: 100%;
    text-align: center;
    color: #2b3c55;
}
.other-products .container-lg{
    position: absolute;
    top: 200px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.other-products .container-lg .left-col,
.other-products .container-lg .right-col{
    text-align: left;
    width: 50%;
}
.other-products .container-lg .left-col h1,
.other-products .container-lg .right-col h1{
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
    color: #2b3c55;
}
.other-products .container-lg .left-col .approval,
.other-products .container-lg .right-col .case{
    list-style: none;
}
.other-products .container-lg .left-col .approval li,
.other-products .container-lg .right-col .case li{
    margin: 20px 0;
    display: flex;
}
.other-products .container-lg .left-col .approval li .dot,
.other-products .container-lg .right-col .case li .dot{
    width: 15px;
    height: 15px;
    background: #2ABCFF;
    border-radius: 50%;
    margin: 5px 10px 5px 0;
}
.other-products .container-lg .left-col .approval li .text,
.other-products .container-lg .right-col .case li .text{
    color: #2b3c55;
}
@media (max-width: 1200px){
    .welcome .container-lg .welcome-row .left-col h1{
        font-size: 42px;
    }
    .other-products .container-lg .right-col{
        margin-top: -20px;
    }
}
@media (max-width: 991px){
    .welcome .welcome-row .left-col h1{
        font-size: 34px !important;
    }
    .welcome .welcome-row .left-col .short-service li{
        padding-left: 20px;
    }
    .welcome .welcome-row .left-col .short-service li .text{
        font-size: 16px !important;
    }
    .c-m-r .archive-sec .right-col h1{
        font-size: 22px;
    }
    .c-m-r .archive-sec{
        min-height: 200vh;
        height: -webkit-max-content;
        height: max-content;
        margin-bottom: 50px;
    }
    .c-m-r .archive-sec .left-col{
        top: 0;
        position: absolute;
        left: 0;
    }
    .c-m-r .archive-sec .left-col img{
        vertical-align: top;
        width: 80%;
    }
    .c-m-r .e-discovery-sec .left-col{
        padding-left: 0;
    }
    .same-bg .key-features .boxes .box{
        height: auto;
        min-height: 350px;
        max-height: 450px;
    }   
    .same-bg .key-features .boxes .box:nth-child(3),
    .same-bg .key-features .boxes .box:nth-child(4){
        min-height: 300px;
    }
    .same-bg .our-product .container{
        margin: 20px auto;
    }
    .c-m-r .archive-sec .right-col .boxes .box{
        width: 150px;
        height: 110px
    }
    .c-m-r .archive-sec .left-col img{
        margin-top: 20%;
        width: 80%;
    }
}
@media (max-width: 800px){
    .other-products{
        height: 130vh;
    }
    .other-products .container-lg{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .other-products .container-lg .left-col,
    .other-products .container-lg .right-col{
        width: 100%;
        padding-left: 20px;
    }
}
@media (max-width: 768px){
    .welcome{
        height: auto;
    }
    .welcome .container-lg .welcome-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .welcome .container-lg .welcome-row .left-col{
        width: 100%;
        height: 70vh;
        padding-top: 110px;
    }
    .welcome .container-lg .welcome-row .right-col{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70vh;
    }
    .welcome .container-lg .welcome-row .right-col img{
        width: 80%;
    }
    .c-m-r h1{
        font-size: 22px;
        width: 100%;
        text-align: center;
        margin-top: -100px;
    }
    .c-m-r .line{
        margin-top: -110px;
    }
    .c-m-r .archive-sec,
    .c-m-r .e-discovery-sec{
        width: 100%;
        height: auto !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .c-m-r .archive-sec .left-col,
    .c-m-r .e-discovery-sec .left-col,
    .c-m-r .archive-sec .right-col,
    .c-m-r .e-discovery-sec .right-col{
        width: 100%;
        height: -webkit-max-content;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .c-m-r .archive-sec .left-col{
        width: 100%;
        height: auto;
        min-height: 100vh;
        margin-bottom: -50%;
        display: flex;
        margin-top: 100px;
        justify-content: center;
    }
    .c-m-r .archive-sec .left-col img{
        width: 80%;
        margin-top: 0;
    }
    .c-m-r .archive-sec .right-col{
        position: absolute;
        left: 0;
        padding-left: 30px;
        top: calc(100vh + 100px);
    }
    .c-m-r .archive-sec .right-col .boxes{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .c-m-r .e-discovery-sec .left-col{
        padding-left: 0;
    }
    .same-bg .key-features .boxes .box{
        width: 100%;
        min-height: 260px;
        height: auto;
    }
    .same-bg .key-features .boxes .box:nth-child(3),
    .same-bg .key-features .boxes .box:nth-child(4){
        min-height: 220px;
    }
    .same-bg .key-features .boxes .box .text{
        margin-bottom: 10px;
    }
    .other-products{
        min-height: 130vh;
        height: auto;
    }
    .same-bg .our-product{
        min-height: 70vh;
    }
}
@media (max-width: 576px){
    .welcome .welcome-row .left-col{
        padding-top: 30%;
    }
    .welcome .welcome-row .left-col h1{
        font-size: 25px !important;
        width: 100% !important;
    }
    .welcome .welcome-row .right-col img{
        width: 80% !important;
    }
    .c-m-r .archive-sec .left-col img{
        width: 80%;
    }
    .c-m-r .e-discovery-sec .right-col img{
        width: 80%;
    }
    .same-bg .key-features .boxes .box .icon{
        position: absolute;
        top: 20px;
        left: 20px;
        width: 80%;
        height: 60px;
    }
    .same-bg .key-features .boxes .box .text{
        position: absolute;
        top: 80px;
        left: 20px;
        width: 90%;
    }
    .same-bg .key-features .boxes .box{
        min-height: 400px;
    }
    .same-bg .key-features .boxes .box:nth-child(3),
    .same-bg .key-features .boxes .box:nth-child(4) {
        min-height: 340px !important;
    }
    
    .same-bg .key-features .boxes .box:nth-child(5),
    .same-bg .key-features .boxes .box:nth-child(6) {
        min-height: 270px !important;
    }
    .same-bg .key-features .boxes .box:last-child{
        min-height: 200px;
    }
    .same-bg .key-features .boxes .box .text h2{
        font-size: 22px;
    }
    .same-bg .our-product{
        min-height: 60vh;
    }
}
@media (max-width: 420px){
    .welcome .welcome-row .left-col{
        padding-top: 50%;
    }
    .welcome .welcome-row .right-col img{
        width: 100%;
    }
    .c-m-r .archive-sec .left-col img{
        width: 100%;
    }
    .c-m-r .e-discovery-sec .right-col img{
        width: 100%;
    }
    .c-m-r .archive-sec{
        min-height: 280vh;
        height: auto;
    }
    .c-m-r .archive-sec .left-col{
        min-height: 60vh !important;
    }
    .c-m-r .archive-sec .right-col{
        position: absolute;
        left: 0;
        top: calc(60vh + 120px);
    }
    .c-m-r .archive-sec .right-col h1
    .c-m-r .archive-sec .right-col p{
        padding-left: 10px;
    }
    .c-m-r .archive-sec .right-col .boxes{
        padding-right: 30px;
    }
    .c-m-r .e-discovery-sec .left-col{
        padding-left: 10px;
    }
    .c-m-r .e-discovery-sec .right-col{
        padding-left: 0px;
    }
    .other-products{
        min-height: 120vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .other-products .container-lg{
        position: absolute;
        top: 170px;
    }
    .other-products .container-lg .left-col h1,
    .other-products .container-lg .right-col h1{
        font-size: 25px;
    }
    .same-bg .our-product{
        min-height: 45vh;
    }
    .other-products .container-lg .left-col, .other-products .container-lg .right-col{
        padding-left: 0px;
    }
}
.demoform{
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
}
.demoform .container-lg{
    width: 90%;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 32px 54px rgba(29, 41, 63, 0.04);
    border-radius: 16px;
    border: 1px solid #e5eaf4;
}
.demoform .container-lg h1{
    color: #2b3c55;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}
.demoform .container-lg .line{
    width: 100px;
    height: 3px;
    background: #3078ff;
    margin: 0 auto;
}
.demoform .container-lg #success-demo{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 300px;
    height: auto;
}
.demoform .container-lg #success-demo .box{
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.demoform .container-lg #success-demo .box img{
    width: 50px !important;
    background: transparent;
    margin: 10px 0;
}
.demoform .container-lg .form{
    padding-top: 30px;
}
.demoform .container-lg .form .part2 .archive,
.demoform .container-lg .form .part2 .case,
.demoform .container-lg .form .part2 .approval,
.demoform .container-lg .form .part2 .row2,
.demoform .container-lg .form .part2 .row1{
    margin-left: 33px !important;
}
.demoform .container-lg .form .part1{
    width: 100%;
    height: 35%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.demoform .container-lg .form .part1 div{
    width: 300px;
    margin: 10px 30px;
    position: relative;
}
.demoform .container-lg .form .part1 div label{
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 18px;
    transition: .3s;
    color: #5a7184;
    opacity: .75;
    font-weight: 450;
}
.demoform .container-lg .form .part1 div input{
    border: none;
    outline: none;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
    padding: 10px 0;
    color: #2b3c55;
    font-weight: 500;
    font-size: 18px;
    width: 350px;
}
.demoform .container-lg .form .part1 div input:focus ~ label{
    top: -12px !important;
    transition: .3s !important;
}
.demoform .container-lg .form .part2 h3{
    padding: 0;
    margin: 0;
    padding-left: 33px;
    color: #2b3c55;
    font-size: 18px;
    font-weight: 500;
    mix-blend-mode: normal;
    opacity: .75;
}
.demoform .container-lg .form .part2 .row1,
.demoform .container-lg .form .part2 .row2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.demoform .container-lg .form .part2 div{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 30%;
}
.demoform .container-lg .form .part2 div input{
    -webkit-appearance: none;
    display: none;
    opacity: 0;
}
.demoform .container-lg .form .part2 div label{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #5a7184;
    position: relative;
}
.demoform .container-lg .form .part2 div span{
    font-size: 18px;
    color: #5a7184;
    margin: 18px 10px;
}
.demoform .container-lg .form .part2 div input:checked ~ label{
    border: 1px solid #3078ff;
}
.demoform .container-lg .form .part2 div input:checked ~ label::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/check.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;
}
.demoform .container-lg .form .part2 .row1, 
.demoform .container-lg .form .part2 .row2{
    display: flex;
}
.demoform .container-lg .form .part3{
    display: flex;
    justify-content: center;
    align-items: center;
}
.demoform .container-lg .form .part3 button{
    width: 200px;
    height: 56px;
    background: #2abcff;
    border: none;
    border-radius: 100px;
    margin-bottom: 54px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    outline: none;
}
@media (max-width: 1200px){
    .demoform .container-lg .form .part1{
        display: flex;
        justify-content: space-evenly;
    }
}
@media (max-width: 991px){
    .demoform .container-lg .form .part1 div input{
        width: 280px;
    }
}
@media (max-width: 768px){
    .demoform .container-lg .form .part2 .archive{
        width: 100%;
    }
    .demoform .container-lg .form .part1{
        justify-content: space-evenly;
    }
    .demoform .container-lg .form .part1 div{
        margin: 15px 20px;
        width: 220px;
    }
    .demoform .container-lg .form .part1 div input{
        width: 200px;
    }
    .demoform .container-lg .form .part2 .row1,
    .demoform .container-lg .form .part2 .row2{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .demoform .container-lg .form .part2 .row1 div,
    .demoform .container-lg .form .part2 .row2 div{
        width: 45%;
    }
    .demoform .container-lg .form .part2 .case,
    .demoform .container-lg .form .part2 .approval{
        width: 100%;
    }
}
@media (max-width: 576px){
    .demoform .container-lg h1{
        font-size: 25px;
    }
    .demoform .container-lg .form .part1{
        display: flex;
        justify-content: flex-start;
    }
    .demoform .container-lg .form .part2 .row1 div,
    .demoform .container-lg .form .part2 .row2 div{
        width: 100%;
    }
}
.footer{
    width: 100%;
    height: 100vh;
    background: #2b3c55;
}
.footer .contact-form{
    width: 100%;
    background: #2b3c55;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.footer .contact-form .left-col,
.footer .contact-form .right-col{
    width: 50%;
    height: auto;
    min-height: 700px;
}
.footer .contact-form .left-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;    
    position: relative;
    padding-left: 150px;
}
.footer .contact-form .left-col h1{
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    width: 100%;
    text-align: left;
}
.footer .contact-form .left-col .line{
    width: 93px;
    height: 3px;
    background: #3078ff;
    margin-left: 0px;
    align-self: flex-start;
    margin-bottom: 16px;
}
.footer .contact-form .left-col p{
    color: #fff;
    font-weight: 450;
    align-self: flex-start;
    margin-bottom: 38px;       
}
.footer .contact-form .left-col ul{
    list-style: none;
    align-self: flex-start;
    width: 100%;
}
.footer .contact-form .left-col ul li{
    margin: 31px 0;
}
.footer .contact-form .left-col ul li span{
    color: #fff;
}
.footer .contact-form .left-col ul li img{
    padding-right: 20px;
}
.footer .contact-form .right-col{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .contact-form .right-col .form,
.footer .contact-form .right-col #success-footer{
    width: 470px;
    height: 456px;
    background: #fff;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    padding-top: 30px;
}
.footer .contact-form .right-col #success-footer{
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
}
.footer .contact-form .right-col .form div{
    width: 90%;
    position: relative;
    margin-left: 20px;
    margin: 10px 30px;
}
.footer .contact-form .right-col .form div input,
.footer .contact-form .right-col .form div textarea{
    border: none;
    outline: none;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
    padding: 10px 0;
    color: #2b3c55;
    font-weight: 500;
    font-size: 18px;
    width: 405px;
}
.footer .contact-form .right-col .form div label{
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 18px;
    transition: .3s;
    color: #5a7184;
    opacity: .75;
    font-weight: 450;
}
.footer .contact-form .right-col .form div input:focus ~ label,
.footer .contact-form .right-col .form div textarea:focus ~ label{
    top: -12px !important;
}
.footer .contact-form .right-col .form div:last-child button{
    width: 200px;
    height: 56px;
    padding: 13px 68px;
    color: #fff;
    background-color: #2abcff;
    border: none;
    border-radius: 100px;
}
.footer .foot{
    width: 100%;
    height: auto;
    background: #25354e;
    min-height: 270px;
}
.footer .foot .container-lg{
    position: relative;
    width: 100% !important;
}
.footer .foot .container-lg img{
    position: absolute;
    top: 40px;
}
.footer .foot .container-lg .tabs{
    display: flex;
    min-width: 100% !important;
    width: auto;
    list-style: none;
    position: absolute;
    top: 50px;
    padding-left: 60%;
}
.footer .foot .container-lg .tabs li{
    margin: 0 10px;
    width: 80px;
    text-align: center;
}
.footer .foot .container-lg .tabs li:last-child{
    margin-right: 0 !important;
}
.footer .foot .container-lg .tabs li a{
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 450;
}
.footer .foot .container-lg .tabs li:hover a,
.footer .foot .container-lg .tabs li a.active{
    color: #2abcff;
}
.footer .foot .container-lg .social-media{
    display: flex;
    min-width: 100% !important;
    width: auto;
    list-style: none;
    position: absolute;
    top: 128px;
    padding-left: 68%;
}
.footer .foot .container-lg .social-media li{
    margin: 0 20px;
}
.footer .foot .container-lg .social-media li #sicon,
.footer .foot .container-lg .social-media li svg{
    width: 18px;
    height: 21px;
    color: #5a7184;
}
.footer .foot .container-lg .social-media li svg{
    width: 18px;
    height: 15px;
}
.footer .foot .container-lg .social-media li:last-child{
    margin-right: 0;
}
.footer .foot .container-lg .terms{
    position: absolute;
    top: 126px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.footer .foot .container-lg .terms p{
    font-size: 14px;
    font-weight: 450;
}
.footer .foot .container-lg .terms p a{
    text-decoration: none;
    color: #ebebeb;
    font-weight: 450;
    font-size: 14px;
}
.footer .foot .container-lg .divider{
    width: 99%;
    height: 1px;
    background: #ccc;
    opacity: 0.2;
    position: absolute;
    top: 175px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.footer .foot .container-lg .bottom{
    position: absolute;
    top: 200px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    min-width: 100%;
    width: auto;
    text-align: center;
}
.footer .foot .container-lg .bottom p{
    color: #ebebeb;
    font-size: 14px;
    font-weight: 450;
}
.footer .contact-form{
    position: relative;
}
.footer .contact-form .back-to-top{
    position: absolute;
    top: -20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #2abcff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .contact-form .back-to-top #icon{
    color: #fff;
    -webkit-transform: scaleY(1.4) scaleX(1.1);
            transform: scaleY(1.4) scaleX(1.1);
}
@media (max-width: 1200px){
    .footer .contact-form .left-col{
        padding-left: 20px;
    }
    .footer .contact-form .left-col .line{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    .footer .foot .container-lg .top .tabs{
        padding-left: 55%;
    }
    .footer .foot .container-lg .top .social-media{
        padding-left: 65%;
    }
}
@media (max-width: 991px){
    .footer .contact-form{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .footer .contact-form .left-col,
    .footer .contact-form .right-col{
        width: 100%;
    }
    .footer .contact-form .left-col h1{
        margin-left: 0;
    }
    .footer .contact-form .left-col p,
    .footer .contact-form .left-col .line,
    .footer .contact-form .left-col ul{
        margin-left: 0;
    }
    .footer .contact-form .right-col{
        display: flex;
        justify-content: flex-start;
        padding-left: 20px;
        margin-top: -25%;
    }
    .footer .foot .container-lg .top .tabs{
        position: absolute;
        top: 50px;
        right: 20px;
    }
    .footer .foot .container-lg .top .social-media{
        position: absolute;
        right: 20px;
    }
    .footer .foot .container-lg .top .terms{
        position: absolute;
        left: 20px;
    }
}
@media (max-width: 768px){
    .footer .foot .container-lg .top .tabs{
        position: absolute;
        top: 108px;
        padding-left: 20px;
    }
    .footer .foot .container-lg .top .social-media{
        position: absolute;
        top: 150px;
        right: 20px;
    }
    .footer .foot .container-lg .top .terms{
        position: absolute;
        top: 150px;
        left: 20px;
    }
    .footer .foot .container-lg .divider{
        top: 180px;
    }
}
@media (max-width: 576px){
    .footer .contact-form .right-col .form,
    .footer .contact-form .right-col #success-footer{
        width: 400px;
    }
    .footer .contact-form .right-col .form input,
    .footer .contact-form .right-col .form textarea{
        width: 350px !important;
    }
    .footer .foot .container-lg .top .social-media{
        position: absolute;
        top: 150px;
        padding-left: 30px;
    }
    .footer .foot .container-lg .top .terms{
        position: absolute;
        top: 190px;
        padding-left: 10px;
    }
    .footer .foot .container-lg .divider{
        top: 220px;
        width: 90%;
    }
    .footer .foot .container-lg .bottom{
        top: 225px;
    }
}
@media (max-width: 450px){
    .footer .contact-form .left-col ul li img{
        padding-right: 5px;
        -webkit-transform: scale(.8);
                transform: scale(.8);
    }
    .footer .contact-form .right-col{
        padding-left: 0;
    }
    .footer .contact-form .right-col .form,
    .footer .contact-form .right-col #success-footer{
        width: 340px;
        margin: 0 auto;
    }
    .footer .contact-form .right-col .form input,
    .footer .contact-form .right-col .form textarea{
        width: 280px !important;
    }
    .footer .foot .container-lg .top .tabs{
        display: flex;
        flex-wrap: wrap !important;
        min-height: 50px;
        height: auto;
    }
    .footer .foot{
        min-height: 350px;
    }
    .footer .foot .container-lg .top .social-media{
        top: 180px;
    }
    .footer .foot .container-lg .top .terms{
        top: 220px;
    }
    .footer .foot .container-lg .divider{
        top: 250px;
    }
    .footer .foot .container-lg .bottom{
        top: 280px;
    }
    .our-products {
        height: 120vh;
    }
}
@media (max-width: 321px){
    .our-products{
        height: 150vh;
    }
    .footer .contact-form .right-col .form,
    .footer .contact-form .right-col #success-footer{
        width: 280px;
    }
    .footer .contact-form .right-col .form input,
    .footer .contact-form .right-col .form textarea{
        width: 250px !important;
    }
    .same-bg .key-features .boxes .box:nth-child(5), .same-bg .key-features .boxes .box:nth-child(6) {
        min-height: 300px !important;
    }
    .same-bg .key-features .boxes .box:nth-child(3), .same-bg .key-features .boxes .box:nth-child(4) {
        min-height: 370px !important;
    }
    .same-bg .key-features .boxes .box {
        min-height: 440px;
    }
}
.navbar{
    width: 100%;
    height: auto;
}
.navbar .container-lg .navbar-brand img{
    width: 306px;
    height: 51px;
}
.navbar .container-lg button{
    border-color: transparent;
    color: transparent;
}
.navbar .container-lg .collapse ul{
    margin-left: auto !important;
    display: flex;
	justify-content: center;
}
.navbar .container-lg .collapse ul li{
    padding: 0 10px;
}
.navbar .container-lg .collapse ul li a{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.2px;
}
.navbar .container-lg .collapse ul li a:hover,
.navbar .container-lg .collapse ul li a.active{
    color: #2abcff !important;
}
.navbar .container-lg .collapse ul li:last-child{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
}
.navbar .container-lg .collapse ul li:last-child a{
    background: #2abcff;
    color: #fff !important;
    padding: 0;
    margin: 0;
    border-radius: 37px;
    padding: 10px 36px;
    padding-top: 12px;
}
.navbar .container-lg .collapse ul li:last-child:hover a:hover,
.navbar .container-lg .collapse ul li:last-child:hover a,
.navbar .container-lg .collapse ul li:last-child a:hover{
    color: #fff !important;
}
@media (min-width: 991px){
    .navbar{
        width: 100% !important;
        height: 70px;
    }
}
@media (max-width: 991px){
    .navbar .container-lg .collapse ul li:last-child{
        width: 100%;
    }
    .navbar .container-lg .collapse ul li:last-child a{
        width: 100% !important;
    }
}
@media (max-width: 576px){
    .navbar .container-lg .navbar-brand{
        width: 80%;
        margin-right: 0;
    }
    .navbar .container-lg .navbar-brand img{
        width: 100%;
    }
}
*{
  padding: 0;
  margin: 0;
  font-family: 'Circular Std', sans-serif !important;
}
