.welcome{
    width: 100%;
    height: 100vh;
    background: #2b3c55;
}
.welcome .container-lg .welcome-row{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center !important;
    flex-direction: row;
}
.welcome .container-lg .welcome-row .left-col,
.welcome .container-lg .welcome-row .right-col{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.welcome .container-lg .welcome-row .left-col{
    justify-content: flex-start;
    text-align: left;
}
.welcome .container-lg .welcome-row .left-col h1{
    margin-bottom: 20px;
    font-size: 42px;
    font-weight: bold;
}
.welcome .container-lg .welcome-row .left-col h1 b{
    color: #2ABCFF;
}
.welcome .container-lg .welcome-row .left-col h1 span{
    color: #fff;
}
.welcome .container-lg .welcome-row .left-col .short-service{
    display: flex;
    flex-direction: column;
    list-style: none;
}
.welcome .container-lg .welcome-row .left-col .short-service li{
    margin: 5px 0;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
}
.welcome .container-lg .welcome-row .left-col .short-service li .line{
    width: 18px;
    height: 4px;
    background: #3de2ff;
    margin-right: 10px;
    margin-left: -30px;
    border-radius: 35px;
    position: absolute;
    top: 10px;
}
.welcome .container-lg .welcome-row .left-col .short-service li .text{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.welcome .container-lg .welcome-row .right-col img{
    width: 90%;
}
.c-m-r{
    width: 100%;
    height: auto;
    background: #fff;
    position: relative;
}
.c-m-r>h1{
    line-height: 46px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 36px;
    width: 100%;
    text-align: center;
    color: #2b3c55;
    font-weight: bold;
}
.c-m-r .line{
    position: absolute;
    top: 155px;
    left: 50%;
    transform: translateX(-50%);
    background: #3078ff;
    width: 100px;
    height: 3px;
}
.c-m-r .archive-sec,
.c-m-r .e-discovery-sec{
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center !important;
    flex-direction: row;
    padding-top: 200px !important;
}
.c-m-r .archive-sec{
    min-height: 120vh;
}
.c-m-r .e-discovery-sec{
    width: 100%;
    height: 10vh;
    position: relative;
    overflow: hidden;
    background: #f8fbff;
    display: flex;
    align-items: center;
    padding-top: 0 !important;
}
.c-m-r .e-discovery-sec .left-col,
.c-m-r .e-discovery-sec .right-col{
    z-index: 1 !important;
    position: relative;
}
.c-m-r .e-discovery-sec::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    border-radius: 50%;
    transform-origin: bottom;
    transform: translateX(-50%) scale(2);
}
.c-m-r .archive-sec .left-col{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 7px;
    position: absolute;
    top: 100px;
    left: 0;
    padding-right: 30px;
    min-height: 100vh;
    height: auto;
}
.c-m-r .archive-sec .right-col{
    position: absolute;
    top: 200px;
    left: 50%;
}
.c-m-r .archive-sec .right-col,
.c-m-r .e-discovery-sec .left-col,
.c-m-r .e-discovery-sec .right-col{
    width: 50%;
    display: flex;
    justify-content: baseline;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.c-m-r .e-discovery-sec .right-col{
    position: relative;
    padding-left: 68px;
    height: auto;
}
.c-m-r .archive-sec .left-col img{
    width: 100%;
}
.c-m-r .e-discovery-sec .right-col img{
    width: 100%;
}
.c-m-r .archive-sec .right-col h1{
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    font-weight: 800;
    color: #2b3c55;
    margin: 0;
    padding: 10px 0 21px 0;
}
.c-m-r .archive-sec .right-col p{
    text-align: left;
    width: 100%;
    color: #2b3c55;
    font-size: 16px;
    font-weight: 450;
    padding: 0;
    margin: 0;
    padding: 0 0 24px 0;
}
.c-m-r .archive-sec .right-col .archive{
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 0 0 15px 0;
}
.c-m-r .archive-sec .right-col .boxes{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.c-m-r .archive-sec .right-col .boxes .box{
    width: 170px;
    height: 130px;
    background: #fff;
    box-shadow: 0px 32px 54px rgba(29, 41, 63, 0.04);
    border-radius: 10px;
    margin: 21px 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 !important;
}
.c-m-r .archive-sec .right-col .boxes .box .text{
    color: #183b56;
    font-size: 14px;
}
.c-m-r .e-discovery-sec .left-col{
    padding: 0;
    margin: 0;
    padding-left: 130px;
}
.c-m-r .e-discovery-sec .left-col h1{
    width: 100%;
    text-align: left;
    color: #2b3c55;
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    padding: 0 0 21px 0;
}
.c-m-r .e-discovery-sec .left-col p{
    width: 100%;
    text-align: left !important;
    color: #2b3c55;
    font-size: 16px;
    font-weight: 450;
    padding: 0;
    margin: 0;
}
.same-bg{
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #F8FBFF 0%, rgba(250, 251, 251, 0.0001) 100%);
}
.same-bg .key-features{
    width: 100%;
    height: auto;
    min-height: 100vh;
}
.same-bg .key-features h1{
    padding-top: 20px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #2b3c55;
    font-size: 36px;
}
.same-bg .key-features .line{
    background: #3078ff;
    width: 100px;
    height: 3px;
    margin: 10px auto;
}
.same-bg .key-features .boxes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.same-bg .key-features .boxes .box{
    width: calc(50% - 20px);
    height: auto;
    min-height: 280px;
    max-height: 450px;
    background: #fff;
    margin: 10px;
    position: relative;
    border-radius: 15px;
    border: 1px #e5eaf4 solid;
    box-shadow: 0px 32px 54px rgba(29, 41, 63, 0.04);
}
.same-bg .key-features .boxes .box:nth-child(3),
.same-bg .key-features .boxes .box:nth-child(4){
    min-height: 220px;
}
.same-bg .key-features .boxes .box:nth-child(5),
.same-bg .key-features .boxes .box:nth-child(6){
    min-height: 200px !important;
}
.same-bg .key-features .boxes .box:last-child{
    min-height: 120px;
    width: 100%;
}
.same-bg .key-features .boxes .box .icon{
    position: absolute;
    top: 20px;
    left: 20px;
}
.same-bg .key-features .boxes .box .text{
    position: absolute;
    top: 20px;
    left: 80px;
    width: 75%;
    padding: 0 10px 10px 0 !important;
}
.same-bg .key-features .boxes .box .text h2{
    font-size: 22px;
    font-weight: 500;
    color: #2b3c55;
}
.same-bg .key-features .boxes .box .text p{
    font-size: 16px;
    font-weight: 450;
    color: #2b3c55;
}
.same-bg .our-product{
    width: 100%;
    height: auto !important;
}
.same-bg .our-product h1{
    margin-top: 50px;
    width: 100%;
    text-align: center;
    color: #2b3c55;
}
.same-bg .our-product .line{
    margin: 10px auto;
    width: 100px;
    height: 3px;
    background: #3078ff;
    margin-bottom: 50px !important;
}
.same-bg .our-product .container{
    width: 100%;
    margin: 5% auto;
}
.other-products{
    width: 100%;
    height: 530px;
    background: #f5f7f9;
    position: relative;
}
.other-products>h1{
    width: 100%;
    text-align: center;
    padding-top: 40px;
    color: #2b3c55;
}
.other-products .line{
    width: 100px;
    height: 3px;
    background: #2abcff;
    margin: 10px auto;
}
.other-products>p{
    width: 100%;
    text-align: center;
    color: #2b3c55;
}
.other-products .container-lg{
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.other-products .container-lg .left-col,
.other-products .container-lg .right-col{
    text-align: left;
    width: 50%;
}
.other-products .container-lg .left-col h1,
.other-products .container-lg .right-col h1{
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
    color: #2b3c55;
}
.other-products .container-lg .left-col .approval,
.other-products .container-lg .right-col .case{
    list-style: none;
}
.other-products .container-lg .left-col .approval li,
.other-products .container-lg .right-col .case li{
    margin: 20px 0;
    display: flex;
}
.other-products .container-lg .left-col .approval li .dot,
.other-products .container-lg .right-col .case li .dot{
    width: 15px;
    height: 15px;
    background: #2ABCFF;
    border-radius: 50%;
    margin: 5px 10px 5px 0;
}
.other-products .container-lg .left-col .approval li .text,
.other-products .container-lg .right-col .case li .text{
    color: #2b3c55;
}
@media (max-width: 1200px){
    .welcome .container-lg .welcome-row .left-col h1{
        font-size: 42px;
    }
    .other-products .container-lg .right-col{
        margin-top: -20px;
    }
}
@media (max-width: 991px){
    .welcome .welcome-row .left-col h1{
        font-size: 34px !important;
    }
    .welcome .welcome-row .left-col .short-service li{
        padding-left: 20px;
    }
    .welcome .welcome-row .left-col .short-service li .text{
        font-size: 16px !important;
    }
    .c-m-r .archive-sec .right-col h1{
        font-size: 22px;
    }
    .c-m-r .archive-sec{
        min-height: 200vh;
        height: max-content;
        margin-bottom: 50px;
    }
    .c-m-r .archive-sec .left-col{
        top: 0;
        position: absolute;
        left: 0;
    }
    .c-m-r .archive-sec .left-col img{
        vertical-align: top;
        width: 80%;
    }
    .c-m-r .e-discovery-sec .left-col{
        padding-left: 0;
    }
    .same-bg .key-features .boxes .box{
        height: auto;
        min-height: 350px;
        max-height: 450px;
    }   
    .same-bg .key-features .boxes .box:nth-child(3),
    .same-bg .key-features .boxes .box:nth-child(4){
        min-height: 300px;
    }
    .same-bg .our-product .container{
        margin: 20px auto;
    }
    .c-m-r .archive-sec .right-col .boxes .box{
        width: 150px;
        height: 110px
    }
    .c-m-r .archive-sec .left-col img{
        margin-top: 20%;
        width: 80%;
    }
}
@media (max-width: 800px){
    .other-products{
        height: 130vh;
    }
    .other-products .container-lg{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .other-products .container-lg .left-col,
    .other-products .container-lg .right-col{
        width: 100%;
        padding-left: 20px;
    }
}
@media (max-width: 768px){
    .welcome{
        height: auto;
    }
    .welcome .container-lg .welcome-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .welcome .container-lg .welcome-row .left-col{
        width: 100%;
        height: 70vh;
        padding-top: 110px;
    }
    .welcome .container-lg .welcome-row .right-col{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70vh;
    }
    .welcome .container-lg .welcome-row .right-col img{
        width: 80%;
    }
    .c-m-r h1{
        font-size: 22px;
        width: 100%;
        text-align: center;
        margin-top: -100px;
    }
    .c-m-r .line{
        margin-top: -110px;
    }
    .c-m-r .archive-sec,
    .c-m-r .e-discovery-sec{
        width: 100%;
        height: auto !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .c-m-r .archive-sec .left-col,
    .c-m-r .e-discovery-sec .left-col,
    .c-m-r .archive-sec .right-col,
    .c-m-r .e-discovery-sec .right-col{
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .c-m-r .archive-sec .left-col{
        width: 100%;
        height: auto;
        min-height: 100vh;
        margin-bottom: -50%;
        display: flex;
        margin-top: 100px;
        justify-content: center;
    }
    .c-m-r .archive-sec .left-col img{
        width: 80%;
        margin-top: 0;
    }
    .c-m-r .archive-sec .right-col{
        position: absolute;
        left: 0;
        padding-left: 30px;
        top: calc(100vh + 100px);
    }
    .c-m-r .archive-sec .right-col .boxes{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .c-m-r .e-discovery-sec .left-col{
        padding-left: 0;
    }
    .same-bg .key-features .boxes .box{
        width: 100%;
        min-height: 260px;
        height: auto;
    }
    .same-bg .key-features .boxes .box:nth-child(3),
    .same-bg .key-features .boxes .box:nth-child(4){
        min-height: 220px;
    }
    .same-bg .key-features .boxes .box .text{
        margin-bottom: 10px;
    }
    .other-products{
        min-height: 130vh;
        height: auto;
    }
    .same-bg .our-product{
        min-height: 70vh;
    }
}
@media (max-width: 576px){
    .welcome .welcome-row .left-col{
        padding-top: 30%;
    }
    .welcome .welcome-row .left-col h1{
        font-size: 25px !important;
        width: 100% !important;
    }
    .welcome .welcome-row .right-col img{
        width: 80% !important;
    }
    .c-m-r .archive-sec .left-col img{
        width: 80%;
    }
    .c-m-r .e-discovery-sec .right-col img{
        width: 80%;
    }
    .same-bg .key-features .boxes .box .icon{
        position: absolute;
        top: 20px;
        left: 20px;
        width: 80%;
        height: 60px;
    }
    .same-bg .key-features .boxes .box .text{
        position: absolute;
        top: 80px;
        left: 20px;
        width: 90%;
    }
    .same-bg .key-features .boxes .box{
        min-height: 400px;
    }
    .same-bg .key-features .boxes .box:nth-child(3),
    .same-bg .key-features .boxes .box:nth-child(4) {
        min-height: 340px !important;
    }
    
    .same-bg .key-features .boxes .box:nth-child(5),
    .same-bg .key-features .boxes .box:nth-child(6) {
        min-height: 270px !important;
    }
    .same-bg .key-features .boxes .box:last-child{
        min-height: 200px;
    }
    .same-bg .key-features .boxes .box .text h2{
        font-size: 22px;
    }
    .same-bg .our-product{
        min-height: 60vh;
    }
}
@media (max-width: 420px){
    .welcome .welcome-row .left-col{
        padding-top: 50%;
    }
    .welcome .welcome-row .right-col img{
        width: 100%;
    }
    .c-m-r .archive-sec .left-col img{
        width: 100%;
    }
    .c-m-r .e-discovery-sec .right-col img{
        width: 100%;
    }
    .c-m-r .archive-sec{
        min-height: 280vh;
        height: auto;
    }
    .c-m-r .archive-sec .left-col{
        min-height: 60vh !important;
    }
    .c-m-r .archive-sec .right-col{
        position: absolute;
        left: 0;
        top: calc(60vh + 120px);
    }
    .c-m-r .archive-sec .right-col h1
    .c-m-r .archive-sec .right-col p{
        padding-left: 10px;
    }
    .c-m-r .archive-sec .right-col .boxes{
        padding-right: 30px;
    }
    .c-m-r .e-discovery-sec .left-col{
        padding-left: 10px;
    }
    .c-m-r .e-discovery-sec .right-col{
        padding-left: 0px;
    }
    .other-products{
        min-height: 120vh;
        height: fit-content;
    }
    .other-products .container-lg{
        position: absolute;
        top: 170px;
    }
    .other-products .container-lg .left-col h1,
    .other-products .container-lg .right-col h1{
        font-size: 25px;
    }
    .same-bg .our-product{
        min-height: 45vh;
    }
    .other-products .container-lg .left-col, .other-products .container-lg .right-col{
        padding-left: 0px;
    }
}